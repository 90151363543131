<script setup>
import { inject, ref, watch } from 'vue'
import LibraryBooksAll from './LibraryBooksAll.vue'
import LibraryBooksPersonal from './LibraryBooksPersonal.vue'

const activeTab = ref(0)
const dataset = inject('dataset')

watch(
  () => activeTab,
  (value) => {
    if (value == 1 && !dataset.user) {
      document.forms.auth.action += '&next=' + window.location.href
      document.forms.auth.submit()
    }
  }
)
</script>

<template>
  <div class="has-background-secondary">
    <div class="container">
      <section class="section py-0">
        <nav class="is-header">
          <button
            type="button"
            class="button is-secondary is-header-menu-item"
            @click="activeTab = 0"
            :class="{ 'is-active': activeTab == 0 }"
          >
            <span class="is-title-normal">Toate cărțile</span>
          </button>

          <button
            type="button"
            class="button is-secondary is-header-menu-item"
            @click="activeTab = 1"
            :class="{ 'is-active': activeTab == 1 }"
          >
            <span class="is-title-normal">Cărțile mele</span>
          </button>
        </nav>
      </section>
    </div>
  </div>

  <div class="container">
    <section class="section">
      <library-books-all v-if="activeTab == 0" />
      <library-books-personal v-else-if="dataset.user" />
    </section>
  </div>
</template>
