export default class ButtonLoadMore {
  constructor() {
    const triggers = document.querySelectorAll('.button[data-load-container]')

    triggers.forEach((trigger) => {
      const target = document.querySelector(
        `[data-container="${trigger.dataset.loadContainer}"]`
      )

      const count = parseInt(trigger.dataset.count)
      const counter = trigger.querySelector('.counter')

      if (target) {
        trigger.addEventListener('click', () => {
          const items = target.querySelectorAll('.is-hidden')

          Array.from(items)
            .slice(0, count)
            .forEach((item) => {
              item.classList.remove('is-hidden')
            })

          if (items.length > count) {
            counter.innerHTML = parseInt(counter.innerHTML) - count
          } else {
            trigger.remove()
          }
        })
      }
    })
  }
}
