import debounce from 'lodash.debounce'
import API from './services/api'

export default class Search {
  constructor() {
    this.modal = document.querySelector('[data-id="modal-search"]')
    this.input = this.modal.querySelector('input')
    this.clearBtn = this.modal.querySelector('.button[data-clear]')
    this.counter = this.modal.querySelector('[data-counter]')
    this.results = this.modal.querySelector('main')

    this.input.addEventListener('keyup', debounce(this.search.bind(this), 150))
    this.clearBtn.addEventListener('click', () => {
      this.reset()
    })
  }

  async search() {
    const value = this.input.value.trim()
    if (value.length < 2) return

    const response = await API.search(
      new URLSearchParams({ query: this.input.value }).toString()
    )

    if (response.count) {
      this.results.innerHTML = response.html
      this.clearBtn.classList.remove('is-hidden')
      this.results.classList.remove('is-hidden')
      this.counter.classList.remove('is-hidden')
      this.counter.querySelector('span').innerHTML = response.count
    }
  }

  reset() {
    this.input.value = ''
    this.input.focus()
    this.results.innerHTML = ''
    this.clearBtn.classList.add('is-hidden')
    this.results.classList.add('is-hidden')
    this.counter.classList.add('is-hidden')
  }
}
