<script setup>
import { onMounted, ref, watch } from 'vue'
import debounce from 'lodash.debounce'
import LibraryModal from './LibraryModal.vue'
import ApiService from '@/js/services/api'

const emit = defineEmits(['update'])

const props = defineProps({
  filters: Object,
  api: String,
})

const books = ref({})
const table = ref({
  perPage: 10,
  page: 1,
  loading: false,
  ordering: 'titlu_carte__titlu',
})

const modal = ref({ active: false, book: null })

const getBooks = async () => {
  table.value.loading = true
  const query = []

  Object.entries(props.filters)
    .filter(([k, v]) => v && v.length)
    .forEach(([k, v]) => {
      if (k == 'autori')
        v.forEach((e) => {
          query.push([k, e._id])
        })
      else query.push([k, Array.isArray(v) ? v.map((e) => e._id) : v])
    })

  books.value = await ApiService[props.api]([
    ['page_size', table.value.perPage],
    ['page', table.value.page],
    ['ordering', table.value.ordering],
    ...query,
  ])

  table.value.loading = false
  emit('update', books.value.count)
}

const openModal = (book) => {
  Object.assign(book, { autori_str: getAuthorString(book.autori) })

  modal.value = {
    active: true,
    book,
  }
}

const onPageChange = (page) => {
  table.value.page = page
  getBooks()
}

const onSort = (field, order) => {
  table.value.ordering = (order == 'desc' ? '-' : '') + field
  getBooks()
}

const updateModal = (status) => {
  modal.value.book.status = status
  modal.value = { active: false, book: null }
}

const getAuthorString = (authors) => {
  return authors.length
    ? authors
        .filter((e) => e.nume)
        .map((e) => e.nume)
        .join(', ')
    : null
}

watch(
  () => props.filters,
  debounce(() => {
    table.value.page = 1
    getBooks()
  }, 250),
  {
    deep: true,
  }
)

onMounted(() => {
  if (props.api) getBooks()
})
</script>

<template>
  <div class="is-text-small mt-v4">
    <b-table
      :data="books.results"
      :paginated="books?.count > 10"
      backend-pagination
      backend-sorting
      :total="books.count"
      :per-page="table.perPage"
      @page-change="onPageChange"
      @sort="onSort"
      :loading="table.loading"
      :default-sort="table.ordering"
      striped
      hoverable
      pagination-order="is-centered"
      pagination-size="is-small"
    >
      <b-table-column
        label="#"
        width="70"
        centered
        v-slot="props"
        cell-class="has-text-black-50"
      >
        {{ props.index + table.perPage * (table.page - 1) + 1 }}
      </b-table-column>

      <b-table-column
        field="titlu_carte__titlu"
        label="Titlu"
        sortable
        v-slot="props"
      >
        {{ props.row.titlu_carte }}
      </b-table-column>

      <b-table-column
        field="autori__nume"
        label="Autor"
        sortable
        v-slot="props"
      >
        {{ getAuthorString(props.row.autori) }}
      </b-table-column>

      <b-table-column
        field="tip__titlu"
        label="Tip"
        width="200"
        sortable
        v-slot="props"
        cell-class="has-text-black-50"
      >
        {{ props.row.tip }}
      </b-table-column>

      <slot>
        <b-table-column
          width="100"
          centered
          v-slot="props"
          cell-class="has-text-right"
        >
          <button
            type="button"
            @click.prevent="openModal(props.row)"
            class="button is-text has-text-weight-medium"
            v-if="props.row.status.status == 'Disponibilă'"
          >
            <span class="is-text-small">Rezervă</span>
            <b-icon icon="arrow-right" size="is-icon-v2" />
          </button>
          <p class="has-text-danger" v-else>Indisponibilă</p>
        </b-table-column>
      </slot>

      <template #empty>Nu există cărți care corespund acestor filtre.</template>
    </b-table>
  </div>

  <LibraryModal
    v-model="modal.active"
    v-bind="{ book: modal.book }"
    @close="modal.active = false"
    @update="updateModal"
  />
</template>
