import { createApp } from 'vue'
import LibraryBase from '@/vue/components/LibraryBase.vue'
import Buefy from '@/js/services/buefy'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('#vue-library')

  if (element) {
    const app = createApp(LibraryBase)
    Buefy(app)

    app.provide('dataset', element.dataset)
    app.mount('#vue-library')
  }
})
