export default class Accordion {
  constructor(selector) {
    const triggers = document.querySelectorAll(
      selector ? selector : '.accordion-trigger'
    )

    if (!triggers) return
    this.onToggle = null

    document.addEventListener('click', (e) => {
      const trigger = e.target.closest('.accordion-trigger')

      if (trigger) {
        e.preventDefault()

        const isExpanded = trigger.getAttribute('aria-expanded') === 'true'
        const content = document.getElementById(
          trigger.getAttribute('aria-controls')
        )

        trigger.classList.toggle('is-active', !isExpanded)
        trigger.setAttribute('aria-expanded', !isExpanded)
        content.hidden = isExpanded
        if (this.onToggle) this.onToggle.call()
      }
    })
  }
}
