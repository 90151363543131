import API from './services/api'

export default class ButtonFollow {
  constructor() {
    const buttons = document.querySelectorAll('[data-follow]')

    buttons.forEach((e) => {
      e.addEventListener('click', async (event) => {
        event.preventDefault()
        e.classList.add('is-loading')

        try {
          // @TODO: check for 403 (not logged in) and redirect to login / popup first?
          const response = await API.toggleFollow(
            e.dataset.follow,
            e.dataset.id,
            e.dataset.favId
          )

          if (e.dataset.removable) {
            e.closest('.column').remove()
            return
          }

          e.classList.toggle('is-outlined')

          if (e.dataset.favId) {
            e.removeAttribute('data-fav-id')
          } else {
            e.setAttribute('data-fav-id', response.id)
          }
        } catch (response) {
          if (response.status == 403) window.location.href = '/auth/login/'
        } finally {
          e.classList.remove('is-loading')
        }
      })
    })
  }
}
