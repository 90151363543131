import Carousel from './carousel'

export default class Program {
  constructor() {
    this.container = document.querySelector('.is-program-head')

    this.carousel = new Carousel('.swiper.is-calendar', {
      freeMode: true,
      edgeSwipeDetection: 'prevent',
      slidesPerView: 'auto',
    })

    if (!this.container) return

    this.headings = document.querySelectorAll('.is-scroll-heading')

    this.container.addEventListener('click', (event) => {
      const target = event.target.closest('.button[data-index]')
      if (target) {
        const date = this.headings[parseInt(target.dataset.index)]

        if (date) {
          date.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }
    })
  }
}
