import {
  ConfigProgrammatic,
  Button,
  Dropdown,
  Icon,
  Input,
  Loading,
  Modal,
  Radio,
  Table,
  Tabs,
} from 'buefy'

import DropdownSelect from '@/vue/components/DropdownSelect.vue'
import VIcon from '@/vue/components/Icon.vue'

export default function (app) {
  app.use(Button)
  app.use(Dropdown)
  app.use(Icon)
  app.use(Input)
  app.use(Loading)
  app.use(Modal)
  app.use(Radio)
  app.use(Table)
  app.use(Tabs)

  // custom
  app.component('dropdown-select', DropdownSelect)
  app.component('VIcon', VIcon)

  ConfigProgrammatic.setOptions({
    defaultIconComponent: 'VIcon',
    defaultIconPack: 'framework7',
    customIconPacks: {
      framework7: {
        iconPrefix: 'f7:',
        internalIcons: {},
      },
    },
    defaultNoticeQueue: false,
    defaultToastDuration: 3000,
  })
}
