<script setup>
import { ref } from 'vue'
import LibraryBooksTable from './LibraryBooksTable.vue'
import FiltersBase from './FiltersBase.vue'

const total = ref(null)
const count = ref(0)
const filters = ref({})

const updateCount = (value) => {
  if (total.value == null) total.value = value
  count.value = value
}
</script>

<template>
  <div>
    <FiltersBase v-model="filters" />

    <p class="mt-v4" v-if="count">
      <span class="has-text-weight-semibold">
        {{ count }}
      </span>
      rezultate afișate din
      <span class="has-text-weight-semibold">{{ total }}</span>
    </p>

    <library-books-table
      v-bind="{ filters, api: 'getBooks' }"
      @update="updateCount"
    >
    </library-books-table>
  </div>
</template>
