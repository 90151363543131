export default class Modals {
  constructor() {
    this.triggers = document.querySelectorAll('[data-modal]')
    this.active = null

    if (!this.triggers) return

    this.initHandlers()
    this.initTriggers()
  }

  initHandlers() {
    const close = (e) => {
      const valid =
        e.key == 'Escape' ||
        e.target.closest('.is-exit') ||
        e.target.classList.contains('modal-background')

      if (valid && this.active) {
        this.active.classList.remove('is-active')
        this.active.removeEventListener('click', this.closeHandler)

        document.documentElement.classList.remove('is-clipped')
        document.removeEventListener('keyup', this.closeHandler)

        this.active = null
      }
    }

    this.closeHandler = close.bind(this)
  }

  initTriggers() {
    this.triggers.forEach((e) => {
      e.addEventListener('click', () => {
        const modal = document.querySelector(
          '.modal[data-id=' + e.dataset.modal + ']'
        )

        if (modal) {
          this.active = modal

          document.documentElement.classList.add('is-clipped')
          document.addEventListener('keyup', this.closeHandler)

          this.active.classList.add('is-active')
          this.active.addEventListener('click', this.closeHandler)

          const inputElement = this.active.querySelector('input')
          if (inputElement) inputElement.focus()
        }
      })
    })
  }
}
