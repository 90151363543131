import API from './services/api'

export default class NewsIndex {
  constructor() {
    const container = document.querySelector('[data-news-index]')

    if (container) {
      this.page = 1
      this.body = container.querySelector('.columns')
      this.trigger = container.querySelector('button[data-trigger]')
      this.query = new URLSearchParams(window.location.search)

      if (this.trigger)
        this.trigger.addEventListener('click', () => {
          this.getPage()
        })
    }
  }

  async getPage() {
    this.query.set('page', ++this.page)
    const response = await API.getNewsPage(this.query.toString())

    if (response.html) this.body.insertAdjacentHTML('beforeend', response.html)
    if (!response.next_page) this.trigger.remove()
  }
}
