<script setup>
import { computed, inject, ref } from 'vue'
import ApiService from '@/js/services/api'

const props = defineProps(['book'])
const model = defineModel()
const loading = ref(false)
const emit = defineEmits(['close', 'update'])
const dataset = inject('dataset')

const postReservation = async () => {
  if (!dataset.user) {
    document.forms.auth.action += '&next=' + window.location.href
    document.forms.auth.submit()
    return
  }

  loading.value = true

  try {
    const result = await ApiService.postReservation({
      carte: props.book.id,
    })

    emit('update', await result.json())
  } finally {
    loading.value = false
  }
}

// const deleteReservation = async (reservation, event) => {
//   const btn = event.target
//   btn.classList.add('is-loading')

//   try {
//     await ApiService.deleteReservation(reservation.status.id)

//     Object.assign(reservation, {
//       status: {
//         rezervare_proprie: true,
//         status: 'Disponibilă',
//       },
//     })

//     emit('update')
//   } finally {
//     btn.classList.remove('is-loading')
//   }
// }
</script>

<template>
  <b-modal v-model="model" v-bind="{ scroll: 'keep' }" :width="480">
    <header>
      <p class="is-heading-normal">Confirmare rezervare</p>
      <button
        type="button"
        class="button is-white is-square is-exit ml-auto"
        aria-label="close"
        @click="emit('close')"
      >
        <b-icon icon="xmark" size="is-icon-2"></b-icon>
      </button>
    </header>
    <main v-if="book">
      <p class="is-text-normal mb-400">
        Ai ales să împrumuți
        <b>
          <i>{{ book.titlu_carte }}</i>
        </b>
        de
        <b>{{ book.autori_str }}</b
        >. După confirmare te așteptăm să o ridici de la Departamentul
        {{ book.locatie }}.
      </p>

      <div class="buttons">
        <button
          type="button"
          class="button is-flex-grow-1 is-accent"
          @click="postReservation"
          :loading="loading"
        >
          Rezervă
        </button>
        <button type="button" class="button is-flex-grow-1 is-secondary">
          Anulează
        </button>
      </div>
    </main>
  </b-modal>
</template>
