<script setup>
import { onMounted, ref } from 'vue'
import ApiService from '@/js/services/api'

const loading = ref(false)
const books = ref([])

const dateFormat = (date) => {
  const d = new Date(date)

  return new Intl.DateTimeFormat('ro-RO', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC',
  }).format(d)
}

const getAuthorString = (authors) => {
  return authors?.length
    ? authors
        .filter((e) => e.nume)
        .map((e) => e.nume)
        .join(', ')
    : null
}

const getBooks = async () => {
  loading.value = true

  books.value = await ApiService.getReservations()
  loading.value = false
}

const deleteReservation = async (reservation, event) => {
  const btn = event.target
  btn.classList.add('is-loading')

  const result = await ApiService.deleteReservation(reservation.id)
  btn.classList.remove('is-loading')

  getBooks()
}

onMounted(() => {
  getBooks()
})
</script>

<template>
  <div>
    <h2 class="is-title-medium mb-v4">
      Rezervările mele

      <sup
        class="is-heading-normal"
        v-if="books.active?.length"
        v-text="books.active.length"
      />
    </h2>

    <b-table
      :data="books.active"
      :paginated="books.active?.length > 10"
      :per-page="10"
      pagination-order="is-centered"
      pagination-size="is-small"
    >
      <template #empty>Nu există cărți în această secțiune</template>

      <b-table-column
        label="#"
        width="70"
        centered
        v-slot="props"
        cell-class="has-text-black-50"
      >
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column
        field="titlu_carte__titlu"
        label="Titlu"
        sortable
        v-slot="props"
      >
        {{ props.row.carte.titlu_carte }}
      </b-table-column>

      <b-table-column
        field="autori__nume"
        label="Autor"
        sortable
        v-slot="props"
      >
        {{ getAuthorString(props.row.carte.autori) }}
      </b-table-column>

      <b-table-column
        field="data_adaugare"
        label="Data rezervare"
        v-slot="props"
      >
        {{ dateFormat(props.row.data_adaugare) }}
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        <p v-if="props.row.data_limita_returnare">
          Termen de returnare <br />
          {{ dateFormat(props.row.data_limita_returnare) }}
        </p>
        <p v-else-if="props.row.data_limita_ridicare">
          De ridicat până la
          {{ dateFormat(props.row.data_limita_ridicare) }}
        </p>
      </b-table-column>

      <b-table-column
        custom-key="actions"
        label=""
        v-slot="props"
        cell-class="has-text-right"
      >
        <b-button
          type="is-text"
          icon-right="xmark"
          @click="deleteReservation(props.row, $event)"
          class="has-text-danger has-text-weight-medium"
          v-if="!props.row.data_predare"
        >
          <span class="is-text-small">Anulează</span>
        </b-button>
      </b-table-column>
    </b-table>

    <h2 class="is-title-medium mb-v4 mt-v7">
      Istoric

      <sup
        class="is-heading-normal"
        v-if="books.istoric?.length"
        v-text="books.istoric.length"
      />
    </h2>

    <b-table
      :data="books.istoric"
      :paginated="books.istoric?.length > 10"
      :per-page="10"
    >
      <template #empty>Nu există cărți în această secțiune</template>

      <b-table-column
        label="#"
        width="70"
        centered
        v-slot="props"
        cell-class="has-text-black-50"
      >
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column
        field="titlu_carte__titlu"
        label="Titlu"
        sortable
        v-slot="props"
      >
        {{ props.row.titlu_carte }}
      </b-table-column>

      <b-table-column
        field="autori__nume"
        label="Autor"
        sortable
        v-slot="props"
      >
        {{ getAuthorString(props.row.autori) }}
      </b-table-column>

      <b-table-column
        field="data_adaugare"
        label="Data rezervare"
        v-slot="props"
      >
        {{ dateFormat(props.row.data_adaugare) }}
      </b-table-column>

      <b-table-column
        field="perioada_imprumut"
        label="Perioada împrumut"
        v-slot="props"
      >
        {{ props.row.perioada_imprumut }}
      </b-table-column>

      <b-table-column
        field="data_returnare"
        label="Dată returnare"
        v-slot="props"
      >
        {{ dateFormat(props.row.data_returnare) }}
      </b-table-column>
    </b-table>
  </div>
</template>
